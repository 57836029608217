@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Prompt&display=swap');

body {
  background-color: #1B1B1B;
}

.border1 {
  border: 1px solid rgb(126, 126, 126);
  display: flex;
}

.item4 {
  grid-column: 2 / span 2;
  grid-row: 2 / span 4;
}

.item2 {
  grid-column: 2/ span 2;
}

.item5 {
  grid-row: 2/span 2
}

.item6 {
  grid-row: 2/span 2
}


.grid-container {
  display: grid;
  grid-gap: 10px;
  background-color: #1B1B1B;
  padding: 10px;
  grid-template: auto auto auto auto;
}

.des {
  border-radius: 10px;
  background-color: white;
  color: black;
  font-weight: 500;
  text-align: center;
  padding: 20px 0;
  font-size: 30px;
  border: 1px solid rgb(176, 176, 176);
}

.des:hover {
  box-shadow: 0px 0px 10px rgb(143, 194, 251);
  transform: scale(1.08);
  transition: 400ms;
}

.item4 {
  background-color: #1B1B1B;
}

.item1 {
  opacity: 0
}

.card1 {
  /* background-color: red; */
}

.item3 {
  opacity: 0
}

.item11 {
  opacity: 0
}

.item14 {
  opacity: 0
}

.backbox {
  background-color: rgba(255, 255, 255, 0.6);
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 10px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* margin:auto; */
}

.backbox2 {
  background-color: rgba(107, 107, 107, 0.7);
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 10px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* margin:auto; */
}

.bgclr {
  background: linear-gradient(to top left, #002b59, #0795b5);
  background-color: white;
}

.boldname {
  font-weight: 600;
  font-size: 35px
}

.btn2 {
  background-color: rgb(0, 0, 0);
  width: 100%;
  border-radius: 8px;
  padding-left: 8px;
  padding-right: 8px;
  color: white;
  font-size: 28px;
  background: linear-gradient(to top left, #3376BD, #08D4F8);
}

.datesub {
  font-size: 25px;
}

.midbox {
  height: 80vh;
  /* width:100%; */
  width: 80vh;
  margin: 10px;
  border: 2px solid rgb(74, 178, 247);
  border-radius: 10px;
}

@media screen and (min-width:899px) {
  .hidet {
    display: none
  }
}

@media screen and (max-width:899px) {
  .hidet2 {
    display: none
  }
}

.backtotop {
  border-radius: 100%;
  height: 45px;
  width: 45px;
  float: right;
  right: 10px;
  bottom: 30px;
  background-color: rgb(0, 162, 255);
  box-shadow: 0px 0px 10px 3px rgb(0, 162, 255);
  animation: backt 2000ms infinite;
}

@keyframes backt {
  0% {
    box-shadow: 0px 0px 10px 3px rgb(0, 162, 255);
  }

  50% {
    box-shadow: 0px 0px 15px 3px rgb(0, 162, 255);
  }

  100% {
    box-shadow: 0px 0px 10px 3px rgb(0, 162, 255);
  }
}

.backlogo {
  margin: auto;
  margin-top: 10px;
  margin-left: 5px;
}

.tops {
  box-shadow: 0px 0px 10px 3px rgb(0, 162, 255);
  background-color: red;
}

.cls {
  background-color: white;
  border-radius: 100%;
  padding: 5px;
  border: 2px solid black;
  box-shadow: 0px 0px 10px 5px black;
  box-shadow: 0px 0px 10px 3px rgb(0, 162, 255);
  font-size: 35px;
  /* display: none; */

}

.footer1 {
  display: grid;
  grid-template-columns: auto auto auto auto;
}

@media screen and (max-width:750px) {
  .footer1 {
    display: grid;
    grid-template-columns: auto auto;
    /* display: none; */
  }
}

@media screen and (max-width:530px) {
  .bsh {
    font-size: 16px;
  }

  .bsc {
    font-size: 13px;
  }
}
@media screen and (max-width:500px) {
  .hidsm{
    display: none;
  }
}
@media screen and (min-width:501px) {
  .hidsm2{
    display: none;
  }
}
@media screen and (max-width:400px) {
  .bsh {
    font-size: 16px;
  }

  .bsc {
    font-size: 12px;
  }

  .disf2 {
    display: flex;
    flex-direction: column;
    flex-direction: column-reverse;
    margin-left: 0px;
  }

  .margin-bottom-footer2 {
    margin-bottom: 15px;
  }
}

svg:hover{
  fill:#08D4F8;
}

::-webkit-scrollbar {
  /* width: 10px; */

  display: none;
}